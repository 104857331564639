import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AlertConfirmationProvider } from "./components/AlertConfirmationContext";
import Protected from "./components/atoms/Protected";
import UnProtected from "./components/atoms/UnProtected";
import { AuthProvider } from "./components/AuthContext";
import { BasePathProvider } from "./components/BasePathContext";
import { CommentsProvider } from "./components/CommentsContext";
import { HLSPlayerProvider } from "./components/HLSPlayerContext";
import { LoadingProvider } from "./components/LoadingContext";
import { NotificationProvider } from "./components/NotificationContext";
import Dashboard from "./components/pages/Dashboard";
import ForgotPassword from "./components/pages/ForgotPassword";
import LoginPage from "./components/pages/LoginPage";
import Main from "./components/pages/Main";
import NotFound from "./components/pages/NotFound";
import Onboarding from "./components/pages/Onboarding";
import SignupPage from "./components/pages/SignupPage";
import Doc from "./components/templates/dashboard/Doc";
import Home from "./components/templates/dashboard/Home";
import IdeaDetails from "./components/templates/dashboard/Idea";
import IdeasListing from "./components/templates/dashboard/IdeasListing";
import Player from "./components/templates/dashboard/Player";
import Settings from "./components/templates/dashboard/Settings";
import TaskDetails from "./components/templates/dashboard/Task";
import MyTasks from "./components/templates/dashboard/TasksListing";
import Workflow from "./components/templates/dashboard/WorkflowEditor";
import { UploadProvider } from "./components/UploadContext";
import { UploadStatusProvider } from "./components/UploadStatusContext";
import RoutePaths from "./constants/RoutePaths";
import reportWebVitals from "./reportWebVitals";
//initial commit
export const myTheme = createTheme({
	palette: {
		primary: {
			main: "#1E2328",
			light: "#444",
			// dark: will be calculated from palette.primary.main,
			contrastText: "#fff",
		},
		secondary: {
			main: "#ffcc08",
			contrastText: "#1E2328",
		},
	},
	typography: {
		fontFamily: [
			"-apple-system",
			"Rethink Sans",
			"Segoe UI",
			"Segoe UI Bold",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		fontSize: 13,
	},
	components: {
		MuiPaper: {
			defaultProps: {
				sx: {
					marginBottom: "10px",
					borderRight: "1px solid #f7f7f7",
					fontSize: 13,
				},
				className: "g-radius--10 g-box-shadow__dark-lightest-v3",
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				paper: {
					borderRadius: 3,
					border: "1px solid #f7f7f7",
					boxShadow: "0 0.3125rem 0.5rem 0 rgba(34, 35, 36, 0.2) !important",
				},
				option: {
					fontSize: "14px !important",
				},
			},
		},
		MuiList: {
			defaultProps: {
				sx: {
					paddingTop: "0",
					paddingBottom: "0",
				},
			},
		},
		MuiListItemButton: {
			defaultProps: {
				sx: {
					paddingTop: 0,
					paddingBottom: 0,
					fontSize: 13,
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					background: "var(--gray-light)",
					fontSize: 13,
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiTableCell: {
			defaultProps: {
				sx: {
					"&.MuiTableCell-root": {
						fontSize: 14,
						padding: "6px 4px 6px 16px",
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					cursor: "default !important",
				},
			},
		},
		MuiInput: {
			defaultProps: {
				sx: {
					height: "2.375rem",
					fontSize: "0.8125rem",
					color: "var(--dark)",
					"&::placeholder": {
						fontStyle: "italic",
					},
				},
			},
		},
	},
});
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	// <React.StrictMode>

	<ThemeProvider theme={myTheme}>
		<UploadProvider>
			<UploadStatusProvider>
				<LoadingProvider>
					<NotificationProvider>
						<AlertConfirmationProvider>
							<AuthProvider>
								<HLSPlayerProvider>
									<CommentsProvider>
										<Router>
											<BasePathProvider>
												<Routes>
													{/* Main component that direct user to correct place */}
													<Route path={"/"} element={<Main />} />
													{/* Url when user is not logged in */}
													<Route
														path={"/login"}
														element={
															<UnProtected>
																<LoginPage />
															</UnProtected>
														}
													/>
													<Route
														path={RoutePaths.SIGNUP}
														element={
															<UnProtected>
																<SignupPage />
															</UnProtected>
														}
													/>
													<Route
														path={"/forgotpassword"}
														element={
															<UnProtected>
																<ForgotPassword />
															</UnProtected>
														}
													/>
													{/* Only logged in user can access this */}
													<Route
														path={"/onboarding"}
														element={
															<Protected>
																<Onboarding />
															</Protected>
														}
													/>
													<Route
														path={"/:workspaceId"}
														element={
															<Protected>
																<Dashboard />
															</Protected>
														}>
														<Route
															index
															element={
																<Protected>
																	<Home />
																</Protected>
															}
														/>
														<Route
															path='tasks'
															element={
																<Protected>
																	<MyTasks />
																</Protected>
															}
														/>
														<Route
															path='tasks/:taskId'
															element={
																<Protected>
																	<TaskDetails />
																</Protected>
															}
														/>
														<Route
															path='workflow/:workflowId'
															element={
																<Protected>
																	<Workflow />
																</Protected>
															}
														/>
														<Route
															path='ideas'
															element={
																<Protected>
																	<IdeasListing />
																</Protected>
															}
														/>
														<Route
															path='ideas/:ideaId'
															element={
																<Protected>
																	<IdeaDetails />
																</Protected>
															}
														/>
														<Route
															path='tasks/:taskId/player/:fileId'
															element={
																<Protected>
																	<Player />
																</Protected>
															}
														/>
														<Route
															path='tasks/:taskId/doc/:fileId'
															element={
																<Protected>
																	<Doc />
																</Protected>
															}
														/>
														<Route
															path='settings'
															element={
																<Protected>
																	<Settings />
																</Protected>
															}
														/>
													</Route>

													<Route path='*' element={<NotFound />} />
												</Routes>
											</BasePathProvider>
										</Router>
									</CommentsProvider>
								</HLSPlayerProvider>
							</AuthProvider>
						</AlertConfirmationProvider>
					</NotificationProvider>
				</LoadingProvider>
			</UploadStatusProvider>
		</UploadProvider>
	</ThemeProvider>

	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
