import { Avatar, Chip } from "@mui/material";
import { useAuth } from "../AuthContext";

interface UserChipProps {
	id: string;
	index: number;
	label?: string;
	avatar?: string;
	rest?: any;
}
const UserChip = (props: any) => {
	const { currentWorkspace, user } = useAuth();
	const { id, index, label, avatar, ...rest } = props;

	let members: any = [];
	if (currentWorkspace) {
		members = currentWorkspace?.members.map((member) => {
			return { label: `${member.firstName} ${member.lastName ?? ""}`, value: member.email, icon: member.avatar ?? "" };
		});
	}
	const getLabel = (value: string) => {
		const filtered = members.filter((option: any) => option.value === value);
		return filtered.length > 0 ? filtered[0].label : value;
	};

	const getIcon = (value: string): string => {
		const filtered = members.filter((option: any) => option.value === value);
		return filtered.length > 0 ? (filtered[0].icon ? filtered[0].icon.toUpperCase() : "") : "";
	};
	const labelX = label ?? getLabel(id);
	return (
		<>
			{labelX && (
				<Chip
					{...rest}
					avatar={<Avatar></Avatar>}
					sx={{ backgroundColor: "transparent", justifyContent: "flex-start" }} //change here doesn't affect other components
					variant='filled'
					size='small'
					key={index}
					label={labelX}
				/>
			)}
		</>
	);
};
export default UserChip;
