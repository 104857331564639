import { Box, Chip, MenuItem, Select } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		},
	},
};
export type MultipleSelectOption = {
	label: string;
	key?: string;
	value: string;
	icon?: string;
};

export interface MultipleSelectProps {
	name: string;
	placeholder?: string;
	label: string;
	value?: MultipleSelectOption[];
	options: MultipleSelectOption[];
	allowMultiple?: boolean;
	disableClearable?: boolean;
	isTaskDrawer?: boolean;
	sx?: any;
	onSelect?: (value: string[] | string) => void;
	handleDelete: (value: string) => void;
	handleAdd: (value: string) => void;
	disabled?: boolean;
}

export const MultipleSelectChipBox = (props: MultipleSelectProps) => {
	let { name, placeholder, label, options, value, handleDelete, handleAdd } = props;
	const handleChange = (value: string) => {
		handleAdd(value);
	};

	return (
		<div>
			<div style={{ display: "flex", flexDirection: "row", marginBottom: "0.5rem" }}>
				<label htmlFor={name} style={{ alignItems: "center", marginBottom: "0" }}>
					{label}
				</label>
			</div>
			<div style={{ marginBottom: "0.5rem", marginLeft: "0" }}></div>
			<div>
				<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginLeft: "0" }}>
					{value &&
						value.map((option: MultipleSelectOption, index) => (
							<Chip
								size='small'
								key={index}
								onDelete={() => {
									handleDelete(option.value);
								}}
								// avatar={<Avatar src={option.icon}></Avatar>}
								label={option.label}
							/>
						))}

					<Select
						MenuProps={MenuProps}
						name={name}
						label={label}
						placeholder={placeholder}
						displayEmpty
						labelId='demo-multiple-chip-label'
						id='demo-multiple-chip'
						// onChange={handleChange}
						renderValue={(selected) => {
							return (
								<div>
									<Chip size='small' label={"+ Add new"} />
								</div>
							);
						}}
						sx={{
							"& .MuiSelect-icon": {
								display: "none",
							},
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "var(--dark)",
								borderWidth: "0rem !important",
							},
							"& .MuiSelect-select": {
								padding: "0 0rem",
							},
						}}>
						{options.map((option, index) => (
							<MenuItem key={index} value={option.value} onClick={() => handleChange(option.value)}>{`${option.label}`}</MenuItem>
						))}
						{options.length == 0 && <MenuItem disabled>Nothing to add here</MenuItem>}
					</Select>
				</Box>
			</div>
		</div>
	);
};
