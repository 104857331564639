import { Box, Grid, Skeleton, Tab, Tabs } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BlobFile, TaskFile } from "../../../models/Interface";
import { BlobStorageService } from "../../../services/BlobStorageService";
import { WorkspaceService } from "../../../services/WorkspaceService";
import { WorkspaceWebService } from "../../../services/WorkspaceWebService";
import { fileIcon, formatBytes, throttle } from "../../../utils/Helpers";
import AutoCompleteSm from "../../atoms/AutoCompleteSm";
import Button from "../../atoms/Button";
import { ariaControls, CustomTabPanel } from "../../atoms/CustomTabs";
import HLSPlayer from "../../atoms/HLSPlayer";
import InputV3 from "../../atoms/InputV3";
import { useAuth } from "../../AuthContext";
import { useComments } from "../../CommentsContext";
import { useLoading } from "../../LoadingContext";
import ButtonMenu from "../../molecules/ButtonMenu";
import FileVersionUpload from "../../molecules/FileVersionUpload";
import { MoreOptionMenuItem } from "../../molecules/MoreOptionMenu";
import RightSidebarLayout from "../../molecules/RightSidebarLayout";
import { useNotification } from "../../NotificationContext";
import { DashboardContext } from "../../pages/Dashboard";
import { useUploadContext } from "../../UploadContext";
import Comments from "./Comments";
import Timer from "./Timer";

const Player = () => {
	const inputFiles = useRef<any>(null);
	const { currentWorkspace, user } = useAuth();
	const states = [{ label: "Draft" }, { label: "Approved" }];
	let { taskId, fileId } = useParams();
	let [searchParams, setSearch] = useSearchParams();
	const { closeSidebar, openSidebar }: any = React.useContext(DashboardContext);
	const { showLoading, hideLoading, isLoading } = useLoading();
	const { showErrorMessage, showSuccessMessage } = useNotification();
	const { isFileUploaded } = useUploadContext();
	const [hasEditAccess, setHasEditAccess] = useState<boolean>(false);
	const [blobVersions, setBlobVersions] = useState<Array<BlobFile>>([]);
	const [currentBlobIndex, setCurrentBlobIndex] = useState<number>(0);
	const [fileName, setFileName] = useState<string | undefined>("");
	const [fileState, setFileState] = useState<any>(states[0]);

	// const docs = [{ uri: blobVersions[currentBlobIndex]?.path ?? "" }];
	const NEW_FILE_ID = "new";

	const moreMenuOptions: MoreOptionMenuItem[] = [
		{ title: "Upload New Version", icon: "upload", isDisable: !hasEditAccess || fileId == NEW_FILE_ID, action: () => handleUploadBtnClick("") },
		{ title: "Download File", icon: "download", isDisable: fileId == NEW_FILE_ID, action: () => handleDownloadVersion() },
		{ title: "Delete Version", icon: "delete", isDisable: !hasEditAccess || fileId == NEW_FILE_ID, action: () => handleDeleteVersion() },
	];

	const versionsMenuOptions: MoreOptionMenuItem[] = blobVersions.map((v, index) => ({
		title: v.originalName,
		subtitle: `v${index + 1} ${v.approved ? "| Approved" : ""}`,
		icon: `${fileIcon(v.fileType)}`,
		isDisable: fileId == NEW_FILE_ID,
		action: () => changeCurrentBlob(index),
	}));

	const changeCurrentBlob = (index: number) => {
		setCurrentBlobIndex(index);
		// handleVersionsMenuClose();
	};

	useEffect(() => {
		closeSidebar();
	}, []);

	const handleFileStateChange = async (state: any) => {
		setFileState(state);
		if (state.label !== fileState.label) {
			showLoading();
			const file = blobVersions[currentBlobIndex];
			const body = { ...file, approved: state.label === "Approved" };
			if (file && taskId) {
				await WorkspaceWebService.uploadFileInTask(taskId, [body])
					.then((res) => {
						blobVersions[currentBlobIndex].approved = state.label === "Approved";
						setBlobVersions(blobVersions);
						showSuccessMessage("File status updated successfully!");
					})
					.catch((err) => showErrorMessage(err.message))
					.finally(() => hideLoading());
			}
		}
	};
	const handleDeleteVersion = () => {};
	const handleShareVersion = () => {};
	const handleDownloadVersion = () => {
		window.location.href = blobVersions[currentBlobIndex].path + `&${new Date().getTime()}`;
	};

	const handleLoadingFile = async () => {
		if (taskId != null && fileId && fileId != NEW_FILE_ID) {
			showLoading();
			await BlobStorageService.getBlobVersions(`${currentWorkspace?.id}/${taskId}/${fileId}`)
				.then(async (res) => {
					const refTaskId = searchParams.get("refTaskId");
					if (refTaskId && refTaskId != "") {
						const result = await WorkspaceService.fetchAllRefTaskFiles(refTaskId);
						const references = result?.files;
						const taskReference = references?.find((ref: any) => ref.taskIdRef == taskId);
						taskReference?.parsedValues?.map((val: BlobFile) => {
							if (fileId) {
								if (val.name?.includes(fileId)) {
									const ind = res.findIndex((azf: BlobFile) => azf.versionId === val.versionId);
									if (ind !== -1) {
										//Updating version from azure data
										val.isCurrentVersion = res[ind].isCurrentVersion;
										//Updating path with latest signature
										val.path = res[ind].path;
										val.approved ? setFileState(states[1]) : setFileState(states[0]);
										setBlobVersions([val]);
										setFileName(val.originalName);
										setCurrentBlobIndex(0);
									}
								}
							}
						});
						setHasEditAccess(() => result.currMemberPermissions.some((p: any) => ["c", "u", "a"].includes(p)));
					} else {
						const result = await WorkspaceService.fetchAllTaskFiles(taskId);
						const files = result?.files as Array<TaskFile>;
						const index = files.findIndex((f) => (fileId ? f.fileName.includes(fileId) : false));
						if (index !== -1) {
							const file = files[index];
							let versions = file.fileVersions;

							versions = versions.map((version, index) => {
								const ind = res.findIndex((azf: BlobFile) => azf.versionId === version.versionId);
								if (ind != -1) {
									setCurrentBlobIndex(index);
									setFileName(versions[index].originalName);
									versions[index].approved ? setFileState(states[1]) : setFileState(states[0]);
									version.isCurrentVersion = res[ind].isCurrentVersion;
									version.path = res[ind].path;
								}
								return version;
							});
							// versions.sort((a, b) => Number(a.approved) - Number(b.approved));
							setBlobVersions(versions);
						}
						setHasEditAccess(() => result.currMemberPermissions.some((p: any) => ["c", "u", "a"].includes(p)));
					}
				})
				.catch((err) => showErrorMessage(err.message))
				.finally(() => {
					hideLoading();
				});
		} else {
			setHasEditAccess(true);
		}
	};

	useEffect(() => {
		throttle(handleLoadingFile, 1500);
	}, [fileId, isFileUploaded]);

	const getApprovedOrLatestVersionIndex = (versions: Array<BlobFile>) => {
		const approvedIndex = versions.findIndex((v) => v.approved);
		return approvedIndex !== -1 ? approvedIndex : versions.length - 1;
	};
	useEffect(() => {
		if (blobVersions.length > 0) {
			const index = getApprovedOrLatestVersionIndex(blobVersions);
			setCurrentBlobIndex(index);
		}
	}, [blobVersions]);

	function handleUploadBtnClick(event: any) {
		// clearStatusQueue();
		if (inputFiles.current) {
			// stopRefreshingProgress();
			inputFiles.current.click();
		}
	}

	const DrawerContent = memo(() => {
		// Preventing rerendering/updating by bringing components states within the component only.
		const [value, setValue] = React.useState(0);
		const handleChange = (event: React.SyntheticEvent, newValue: number) => {
			setValue(newValue);
		};
		const location = useLocation();
		const { comments, setFileDetails, setComments, loading, setLoading, fetchComments } = useComments();
		useEffect(() => {
			if (blobVersions && currentBlobIndex >= 0 && currentBlobIndex < blobVersions.length) {
				setFileDetails({
					taskId: taskId ?? "",
					fileName: blobVersions[currentBlobIndex]?.name ?? "",
					fileVersionId: blobVersions[currentBlobIndex]?.versionId ?? "",
				});
			}
		}, [currentBlobIndex, blobVersions]);

		useEffect(() => {
			setLoading(true);
			setComments([]);
		}, [location]);

		useEffect(() => {
			if (taskId && blobVersions?.[currentBlobIndex]?.name && blobVersions?.[currentBlobIndex]?.versionId) {
				fetchComments(taskId, blobVersions?.[currentBlobIndex]?.name, blobVersions?.[currentBlobIndex]?.versionId);
			}
		}, [taskId, blobVersions?.[currentBlobIndex]?.name, blobVersions?.[currentBlobIndex]?.versionId]);

		return (
			<Grid className='g-padding-y-10--xs g-padding-x-0--xs g-bg-color--gray-light'>
				<Box sx={{ borderBottom: 0, borderColor: "divider" }}>
					<Tabs value={value} onChange={handleChange} centered variant='standard' scrollButtons='auto' aria-label='basic tabs example'>
						<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Comments' {...ariaControls(0)} />
						<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='File Information' {...ariaControls(1)} />
					</Tabs>
				</Box>
				<CustomTabPanel value={value} index={1}>
					{blobVersions.length > 0 && (
						<div className='g-margin-t-10--xs'>
							<div className=' g-padding-x-10--xs g-padding-y-10--xs g-radius--10 ' style={{ backgroundColor: "#D9D9D9" }}>
								<div style={{ borderBottom: "1px solid #ddd" }}>
									<p
										className='g-font-size-14--xs g-font-weight--700'
										style={{ textOverflow: "ellipsis", overflow: "hidden", lineBreak: "normal", lineHeight: "normal" }}>
										{blobVersions[currentBlobIndex].originalName}
									</p>
								</div>
								<div className='g-display-flex--xs' style={{ justifyContent: "space-around" }}>
									<div className='g-margin-t-10--xs'>
										<div className='g-font-weight--600' style={{ color: "#777" }}>
											File Size
										</div>
										<div className='g-font-weight--700'>{formatBytes(blobVersions[currentBlobIndex].fileSize)}</div>
									</div>
									<div className='g-margin-t-10--xs'>
										<div className='g-font-weight--600' style={{ color: "#777" }}>
											File Type
										</div>
										<div className='g-font-weight--700'>{blobVersions[currentBlobIndex].fileType}</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</CustomTabPanel>
				<CustomTabPanel value={value} index={0}>
					{loading ? (
						<div className='g-margin-t-20--xs g-hor-divider__solid--gray'>
							<Skeleton variant='text' className='g-margin-l-10--xs' width={"500px"} sx={{ fontSize: "1.6rem" }} />
							<Skeleton variant='text' className='g-margin-l-10--xs' width={"200px"} sx={{ fontSize: "1.2rem" }} />
						</div>
					) : (
						comments
							.filter((comment) => comment.parentId == "null")
							.map((value, index) => (
								<Comments key={index} props={value} isImage={blobVersions[currentBlobIndex]?.fileType?.includes("image")} parentComment={true} />
							))
					)}
				</CustomTabPanel>
			</Grid>
		);
	});

	const isAnyVersionApproved = () => {
		return blobVersions.some((v) => v.approved);
	};

	const isCurrentVersionApproved = () => {
		return blobVersions[currentBlobIndex].approved;
	};

	const currentTimeNow2 = useRef(0);
	function getCurrentTime(time: any) {
		currentTimeNow2.current = time;
	}

	const navigate = useNavigate();

	// Function to handle back navigation
	const handleBackClick = () => {
		navigate(-1);
	};
	const [isHlsStreamAvailable, setIsHlsAvailable] = useState<boolean | null>(null);
	const [apiResponse, setApiResponse] = useState<boolean>(false);
	const fetchStreamingStatus = async () => {
		await WorkspaceWebService.isStreamingEndpointActive()
			.then((res) => {
				if (res) {
					setIsHlsAvailable(true);
				} else setIsHlsAvailable(false);
			})
			.catch((err) => {
				//   showErrorMessage(err.message);
				setIsHlsAvailable(false);
			})
			.finally(() => {
				setApiResponse(true);
			});
	};

	useEffect(() => {
		if (!isHlsStreamAvailable) {
			fetchStreamingStatus();
		}
	}, []);

	return (
		<div>
			<FileVersionUpload taskId={taskId ?? ""} blobVersions={blobVersions} currentBlobIndex={currentBlobIndex} inputFiles={inputFiles} />
			<Grid
				sx={{ zIndex: 1000, position: "sticky", display: "block", top: 0 }}
				className='g-padding-x-20--xs g-padding-y-10--xs g-hor-divider__solid--gray g-bg-color--white'>
				<Grid container className='g-margin-t-0--xs'>
					<Grid item xs={9} style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
						<i onClick={handleBackClick} className='material-symbols-sharp' style={{ cursor: "pointer" }}>
							arrow_back
						</i>
						<div className='g-display-flex--xs' style={{ justifyContent: "flex-start" }}>
							<div className='g-content-center-y--xs g-width-450--xs g-margin-r-5--xs'>
								<i className='material-symbols-sharp g-margin-r-5--xs g-font-size-20--xs'>{fileIcon(blobVersions[currentBlobIndex]?.fileType)}</i>
								<InputV3 disabled={true} value={fileName} name='fileName' />
							</div>
							<div className='g-margin-r-5--xs'>
								<ButtonMenu
									menuOptions={versionsMenuOptions}
									style={{ height: "33px" }}
									text={`v${currentBlobIndex + 1}`}
									icon='expand_more'
									color={"light"}
									radius={4}
									variant='contained'
								/>
							</div>

							<div className='g-margin-r-5--xs'>
								<Button
									style={{ height: "33px" }}
									text={"Upload New Version"}
									onClick={handleUploadBtnClick}
									icon='cloud_upload'
									color={"light"}
									radius={4}
									disabled={!hasEditAccess || isAnyVersionApproved()}
									variant='contained'></Button>
							</div>
						</div>
					</Grid>
					<Grid item xs={3}>
						<div className='g-display-flex--xs' style={{ justifyContent: "flex-end" }}>
							<div className='g-display-flex--xs g-width-100-percent--xs g-margin-r-10--xs' style={{ alignItems: "center", justifyContent: "flex-end" }}>
								<div className='g-width-200--xs g-margin-l-5--xs'>
									<AutoCompleteSm
										onChange={(e: any, v: any) => handleFileStateChange(v)}
										options={states}
										value={fileState}
										defaultValue={states[0]}
										placeholder='Select State'
										disabled={!hasEditAccess || (isAnyVersionApproved() ? !isCurrentVersionApproved() : false)}
									/>
								</div>
							</div>
							<div className='g-margin-r-10--xs'>
								<Button onClick={handleShareVersion} text='Share' icon='share' color={"primary"} radius={4} variant='contained'></Button>
							</div>
							<div className='g-margin-r-5--xs'>
								<ButtonMenu menuOptions={moreMenuOptions} style={{ height: "33px" }} icon='more_horiz' color={"light"} radius={4} variant='contained' />
							</div>
						</div>
					</Grid>
				</Grid>
			</Grid>
			<RightSidebarLayout isDrawerOpen={true} drawerWidth={340} drawer={<DrawerContent />}>
				<div style={{ width: "85%", margin: "auto" }}>
					<Grid container>
						<Grid item xs={12}>
							{blobVersions[currentBlobIndex]?.fileType?.includes("video") &&
								(apiResponse == true ? (
									<HLSPlayer
										originalSrc={blobVersions[currentBlobIndex]?.path ?? ""}
										hlsSrc={blobVersions[currentBlobIndex]?.mkStreamUrlHls}
										getCurrentTime={getCurrentTime}
										isHlsStreamAvailable={isHlsStreamAvailable}
									/>
								) : (
									<>
										<div style={{ height: "40vh", width: "", display: "flex", justifyContent: "center", alignItems: "center" }}>
											<CircularProgress size='3rem' />
										</div>{" "}
									</>
								))}
							{blobVersions[currentBlobIndex]?.fileType?.includes("image") && (
								<img
									style={{ maxHeight: "90vh", maxWidth: "100%", marginLeft: "auto", marginRight: "auto" }}
									src={blobVersions[currentBlobIndex].path}
									alt={blobVersions[currentBlobIndex].originalName}
								/>
							)}
							{blobVersions[currentBlobIndex]?.fileType?.includes("audio") && (
								<>
									<audio controls style={{ height: "20vh", width: "100%", marginLeft: "auto", marginRight: "auto" }}>
										<source src={blobVersions[currentBlobIndex].path} type={blobVersions[currentBlobIndex].fileType} />
									</audio>
								</>
							)}
						</Grid>
						{blobVersions[currentBlobIndex]?.fileType?.includes("video") ? <Timer isVideo={true} /> : <Timer isVideo={false} />}
					</Grid>
				</div>
			</RightSidebarLayout>
		</div>
	);
};

export default Player;
