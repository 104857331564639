import { Chip, LinearProgress, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TaskStatus } from "../../../constants/TaskStatus";
import { Task } from "../../../models/Interface";
import { WorkspaceWebService } from "../../../services/WorkspaceWebService";
import { ariaControls, CustomTabPanel } from "../../atoms/CustomTabs";
import StatusChipMenu from "../../atoms/StatusChipMenu";
import { EnhancedTable } from "../../atoms/Table";
import UserChip from "../../atoms/UserChip";
import { useAuth } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";
import NoData from "../../molecules/NoData";
import { useNotification } from "../../NotificationContext";

const TasksListing = (props: any) => {
	const { currentWorkspace, user, isAdmin } = useAuth();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const [localLoading, setLocalLoading] = useState<any>(true);
	const [rows, setRows] = useState<any>([]);
	const [rowsAssigned, setRowsAssigned] = useState<any>([]);
	const [rowsReview, setRowsReview] = useState<any>([]);
	const { showErrorMessage, showSuccessMessage } = useNotification();
	const [allRows, setAllRows] = useState<any>([]);

	const [todoRows, setTodoRows] = useState<any>([]);
	const [draftRows, setDraftRows] = useState<any>([]);
	const [inprogressRows, setInprogressRows] = useState<any>([]);
	const [needsreviewRows, setNeedsreviewRows] = useState<any>([]);
	const [completedRows, setCompletedRows] = useState<any>([]);

	const [allTodoRows, setAllTodoRows] = useState<any>([]);
	const [allDraftRows, setAllDraftRows] = useState<any>([]);
	const [allInprogressRows, setAllInprogressRows] = useState<any>([]);
	const [allNeedsreviewRows, setAllNeedsreviewRows] = useState<any>([]);
	const [allCompletedRows, setAllCompletedRows] = useState<any>([]);

	function createData(row: Task) {
		return {
			data: {
				id: row.id,
				title: row.data?.title,
				workType: row.type,
				status: row.status ?? "TODO",
				workflow: row.workflow,
				idea: row.idea,
				reviewers: row.data?.reviewers,
				assignedTo: row.data?.assignedTo,
			},
		};
	}

	const handleTaskStateChange = async (state: any, id: string) => {
		const formattedState = state.toUpperCase().replace(/\s+/g, "");
		const finalState = formattedState === "NEEDSREVIEW" ? "NEEDS_REVIEW" : formattedState;
		const updatedRows = allRows.map((row: any) => {
			if (row.data.id === id) {
				return { ...row, data: { ...row.data, status: finalState } };
			}
			return row;
		});
		try {
			if (id) {
				await WorkspaceWebService.updateTaskStatus(id, finalState).then((res) => {
					showSuccessMessage("Task status updated successfully!");
					setAllRows(updatedRows); // only change the tables if we actually changed status in database
				});
			}
		} catch (error) {
			console.error("Error updating task status:", error);
		}
		// fetchAllTasks();
	};

	const ReviewersCell = ({ reviewers }: { reviewers: any[] }) => {
		const [showAll, setShowAll] = useState<boolean>(false);
		return (
			<div style={{ display: "flex", flexDirection: "row" }}>
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
					{showAll && reviewers.map((option: any, index: any) => <UserChip key={index} className='g-margin-r-5--xs' id={option} index={index} />)}
				</div>
				{!showAll && <UserChip key={0} className='g-margin-r-5--xs' id={reviewers[0]} index={0} />}
				{reviewers.length > 1 && (
					<Chip
						label={showAll ? "Hide" : `+${reviewers.length - 1}`}
						size={"small"}
						onClick={() => {
							setShowAll(!showAll);
						}}
					/>
				)}
			</div>
		);
	};
	const mapper = (rows: Array<Task>) => {
		return rows.map((el: any) => createData(el));
	};
	const header = [
		// {
		// 	id: "id",
		// 	label: "Id",
		// 	render: (item: any) => item.id,
		// },
		{
			id: "idea",
			label: "Idea",
			render: (item: any) => <Link to={`${item.id}`}>{item.idea?.name}</Link>,
		},
		{
			id: "title",
			label: "Work Type",
			render: (item: any) => (
				<div className='g-display-flex--xs' style={{ justifyContent: "space-between" }}>
					<Link to={`${item.id}`}>{item.title}</Link>
					<i className='material-symbols-sharp'>more_horiz</i>
				</div>
			),
		},
		{
			id: "workflowType",
			label: "Workflow",
			render: (item: any) => item.workflow?.name,
		},
		{
			id: "status",
			label: "Status",
			render: (item: any) => (
				<StatusChipMenu key={item.id} status={item.status} handleTaskStateChange={(newStatus: string) => handleTaskStateChange(newStatus, item.id)} />
			),
		},
		{
			id: "assignedTo",
			label: "Assigned To",
			render: (item: any) => <UserChip id={item.assignedTo} index={0} />,
		},
		{
			id: "reviewers",
			label: "Reviewers",
			render: (item: any) => {
				return <ReviewersCell reviewers={item.reviewers} />;
			},
		},
	];

	const fetchAllTasks = async () => {
		if (currentWorkspace?.id) {
			showLoading();
			setLocalLoading(true);
			await WorkspaceWebService.fetchAllUserTasks(currentWorkspace?.id)
				.then(async (res: any) => {
					const allRows = mapper(res);
					setAllRows(allRows);
					const assignedRows = allRows.filter(
						(r: any) => r.data?.assignedTo === user.email || (r.data?.reviewers.includes(user.email) && r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW])
					);
					setRowsAssigned(assignedRows);
					const draftRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.DRAFT] && r.data?.assignedTo === user.email);
					const todoRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.TODO] && r.data?.assignedTo === user.email);
					const inprogressRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.INPROGRESS] && r.data?.assignedTo === user.email);
					const needsreviewRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW] && r.data?.reviewers.includes(user.email));
					const completedRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.COMPLETE] && r.data?.assignedTo === user.email);

					const alldraftRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.DRAFT]);
					const alltodoRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.TODO]);
					const allinprogressRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.INPROGRESS]);
					const allneedsreviewRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW]);
					const allcompletedRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.COMPLETE]);

					setDraftRows(draftRows);
					setTodoRows(todoRows);
					setInprogressRows(inprogressRows);
					setNeedsreviewRows(needsreviewRows);
					setCompletedRows(completedRows);

					setAllDraftRows(alldraftRows);
					setAllTodoRows(alltodoRows);
					setAllInprogressRows(allinprogressRows);
					setAllNeedsreviewRows(allneedsreviewRows);
					setAllCompletedRows(allcompletedRows);
				})
				.catch((err) => showErrorMessage(err.message))
				.finally(() => {
					hideLoading();
					setLocalLoading(false);
				});
		}
	};
	useEffect(() => {
		fetchAllTasks();
	}, [currentWorkspace]);
	const [value, setValue] = useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		const updateRows = () => {
			const assignedRows = allRows.filter(
				(r: any) => r.data?.assignedTo === user.email || (r.data?.reviewers.includes(user.email) && r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW])
			);
			setRowsAssigned(assignedRows);
			const draftRowss = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.DRAFT] && r.data?.assignedTo === user.email);
			const todoRowss = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.TODO] && r.data?.assignedTo === user.email);
			const inprogressRowss = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.INPROGRESS] && r.data?.assignedTo === user.email);
			const needsreviewRowss = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW] && r.data?.reviewers.includes(user.email));
			const completedRowss = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.COMPLETE] && r.data?.assignedTo === user.email);

			const alldraftRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.DRAFT]);
			const alltodoRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.TODO]);
			const allinprogressRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.INPROGRESS]);
			const allneedsreviewRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.NEEDS_REVIEW]);
			const allcompletedRows = allRows.filter((r: any) => r.data.status === TaskStatus[TaskStatus.COMPLETE]);

			setDraftRows(draftRowss);
			setTodoRows(todoRowss);
			setInprogressRows(inprogressRowss);
			setNeedsreviewRows(needsreviewRowss);
			setCompletedRows(completedRowss);

			setAllDraftRows(alldraftRows);
			setAllTodoRows(alltodoRows);
			setAllInprogressRows(allinprogressRows);
			setAllNeedsreviewRows(allneedsreviewRows);
			setAllCompletedRows(allcompletedRows);
		};
		updateRows();
	}, [allRows]);

	return (
		<div className='g-padding-x-20--xs g-margin-t-30--xs'>
			<div className='g-display-flex--xs g-margin-b-20--xs' style={{ justifyContent: "space-between" }}>
				<h3>Tasks</h3>
			</div>

			{isAdmin() && (
				<div className='g-display-flex--xs' style={{ justifyContent: "space-between" }}>
					<div>
						<Tabs value={value} onChange={handleChange} variant='standard' scrollButtons='auto' aria-label='basic tabs example'>
							<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Your Tasks' {...ariaControls(0)} />
							<Tab sx={{ px: 2, py: 0, textTransform: "none", fontSize: 15 }} label='Workspace Tasks' {...ariaControls(1)} />
						</Tabs>
					</div>
				</div>
			)}
			<CustomTabPanel value={value} index={0}>
				{localLoading && (
					<div className='g-content-center-xy--xs g-height-600--xs'>
						<LinearProgress className='g-margin-t-40--xs g-width-150--xs' color='secondary' />
					</div>
				)}
				{todoRows && todoRows.length > 0 && !localLoading && (
					<EnhancedTable rows={todoRows} title={"TO DO"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{inprogressRows && inprogressRows.length > 0 && !localLoading && (
					<EnhancedTable rows={inprogressRows} title={"IN PROGRESS"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{needsreviewRows && needsreviewRows.length > 0 && !localLoading && (
					<EnhancedTable rows={needsreviewRows} title={"NEEDS REVIEW"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{draftRows && draftRows.length > 0 && !localLoading && (
					<EnhancedTable rows={draftRows} title={"DRAFT"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{completedRows && completedRows.length > 0 && !localLoading && (
					<EnhancedTable rows={completedRows} title={"COMPLETE"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{!isLoading && !localLoading && rowsAssigned.length == 0 && <NoData />}
			</CustomTabPanel>

			<CustomTabPanel value={value} index={1}>
				{localLoading && (
					<div className='g-content-center-xy--xs g-height-600--xs'>
						<LinearProgress className='g-margin-t-40--xs g-width-150--xs' color='secondary' />
					</div>
				)}
				{allTodoRows && allTodoRows.length > 0 && !localLoading && (
					<EnhancedTable rows={allTodoRows} title={"TO DO"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{allInprogressRows && allInprogressRows.length > 0 && !localLoading && (
					<EnhancedTable rows={allInprogressRows} title={"IN PROGRESS"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{allNeedsreviewRows && allNeedsreviewRows.length > 0 && !localLoading && (
					<EnhancedTable rows={allNeedsreviewRows} title={"NEEDS REVIEW"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{allDraftRows && allDraftRows.length > 0 && !localLoading && (
					<EnhancedTable rows={allDraftRows} title={"DRAFT"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{allCompletedRows && allCompletedRows.length > 0 && !localLoading && (
					<EnhancedTable rows={allCompletedRows} title={"COMPLETE"} columns={header} paginationOptions={[5]} showHalfTable={true}></EnhancedTable>
				)}
				{!isLoading && !localLoading && allRows.length == 0 && <NoData />}
			</CustomTabPanel>
		</div>
	);
};

export default TasksListing;
