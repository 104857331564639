import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SC from "../../../constants/StringConstants";
import { GetTaskStatusList, StatusLabels } from "../../../constants/TaskStatus";
import { BlobFile, Reference, Task, TaskTemplate } from "../../../models/Interface";
import { BlobStorageService } from "../../../services/BlobStorageService";
import { WorkspaceWebService } from "../../../services/WorkspaceWebService";
import { fileThumbnail, throttle } from "../../../utils/Helpers";
import AutoCompleteSm from "../../atoms/AutoCompleteSm";
import InputV3 from "../../atoms/InputV3";
import { useAuth } from "../../AuthContext";
import ButtonMenu from "../../molecules/ButtonMenu";
import FileManager from "../../molecules/FileManager";
import { MoreOptionMenuItem } from "../../molecules/MoreOptionMenu";
import RightSidebarLayout from "../../molecules/RightSidebarLayout";
import TaskDrawer from "../../molecules/TaskDrawer";
import { useNotification } from "../../NotificationContext";
import TaskSkeleton from "../../skeletons/TaskSkeleton";
import { useUploadContext } from "../../UploadContext";

const TaskDetails = () => {
	const { currentWorkspace } = useAuth();
	let { taskId } = useParams();
	const navigate = useNavigate();
	const { showErrorMessage, showSuccessMessage } = useNotification();
	const { isFileUploaded } = useUploadContext();

	const states = GetTaskStatusList();
	const [task, setTask] = useState<Task>();
	const [taskState, setTaskState] = useState<any>(states[0]);
	const [filesInTask, setFilesInTask] = useState<Array<BlobFile>>([]);
	const [localLoading, setLocalLoading] = useState<any>(true);

	const NEW_TASK_ID = SC.NEW_ID;

	const moreMenuOptions: MoreOptionMenuItem[] = [{ title: "Delete Task", icon: "delete", isDisable: taskId == NEW_TASK_ID, action: () => handleDeleteIdea() }];

	const handleTaskStateChange = async (state: any) => {
		if (state.value !== taskState.value && taskId) {
			setTaskState(state);
			await WorkspaceWebService.updateTaskStatus(taskId, state.value).then((res) => {
				showSuccessMessage("Task status updated successfully!");
			});
		}
	};

	const handleLoadingTask = async () => {
		// Check task and workspace is valid
		if (taskId != null && taskId != NEW_TASK_ID) {
			setLocalLoading(true);
			// Fetch Task by Id
			await WorkspaceWebService.fetchTaskById(taskId)
				.then(async (res) => {
					const taskResponse = res as Task;
					if (Object.keys(taskResponse).length > 0) {
						setTask(taskResponse);
						setTaskState({ label: StatusLabels.getLabel(taskResponse.status), value: taskResponse.status });
						setLocalLoading(true);

						const data = taskResponse.data as TaskTemplate;

						const files = data.inputs;
						// Fetch all files assc. with task
						const azfiles = await getAzFilesInTask(taskId);
						// Filter only current versions details to show
						const azFilesCurrentVersions = azfiles.filter((azf: BlobFile) => azf.isCurrentVersion);

						let taskFiles: BlobFile[] = [];
						if (azFilesCurrentVersions && azFilesCurrentVersions.length > 0) {
							taskFiles = files.map((file) => {
								// Populating data from azure file current version to the task file.
								const versions = file.fileVersions;
								// Identify if any of the version is approved!
								let isAnyApproved = false;
								const versionsWithData = versions.map((version) => {
									const azFile = azFilesCurrentVersions.find((azf: any) => version.name === azf.name && azf.isCurrentVersion);
									if (azFile) {
										version.taskId = taskId;
										version.path = azFile.path;
										version.thumbnail = azFile.thumbnail;
									}
									if (!isAnyApproved) isAnyApproved = version.approved ?? false;
									return version;
								});
								let finalIndex = 0;
								// Get the index of the current file version
								const index = versionsWithData.findIndex((version) => {
									return azFilesCurrentVersions.some((azf: any) => version.name === azf.name && azf.isCurrentVersion);
								});

								if (index >= 0) {
									finalIndex = index;
								}

								const approvedIndex = versions.findIndex((v) => v.approved);
								if (approvedIndex >= 0) {
									finalIndex = approvedIndex;
								}

								const fileTileVersion: BlobFile = versionsWithData[finalIndex];
								fileTileVersion.approved = isAnyApproved;
								// Setting thumbnail for the file
								fileTileVersion.versionNumber = finalIndex;
								fileTileVersion.thumbnail = fileThumbnail(fileTileVersion.fileType, fileTileVersion.path);
								return fileTileVersion;
							});
						}

						// Get all the referenced files
						const references = data.references;
						let refFiles =
							references
								?.map((ref: Reference) => {
									const values = ref?.parsedValues as Array<BlobFile>;
									ref.parsedValues = values.map((v: BlobFile): BlobFile => {
										v.taskId = ref.taskIdRef;
										var azBlobFormat = { ...v };
										azBlobFormat.name = `${taskResponse.workspaceId}/${ref.taskIdRef}/${v.name}`;
										v.path = BlobStorageService.getBlobUrl(azBlobFormat);
										v.thumbnail = fileThumbnail(v.fileType, v.path);
										v.versionNumber = 0;
										v.originalName = `${SC.REFERENCE_FOLDER}/${v.originalName}`;
										v.isReferenced = true;
										return v;
									});
									return ref.parsedValues as Array<BlobFile>;
								})
								.filter((blb): Boolean => blb !== undefined)
								.flat(1)
								.flat(1)
								.filter((blb): Boolean => blb !== undefined) ?? [];
						setFilesInTask([...taskFiles, ...refFiles]);
					}
				})
				.catch((err) => {
					showErrorMessage(err.message);
				})
				.finally(() => {
					setLocalLoading(false);
				});
		}
	};

	const getAzFilesInTask = async (taskId: string) => {
		if (taskId && currentWorkspace?.id) {
			return BlobStorageService.getBlobsListAtPath(`${currentWorkspace?.id}/${taskId}/`).then((res: any) => {
				const blobFiles = res;
				return blobFiles;
			});
		}
	};

	// Refresh should work fine without warning, when user is not uploading files.
	useEffect(() => {
		throttle(handleLoadingTask, 1500);
	}, [isFileUploaded, taskId]);

	// Function to handle back navigation
	const handleBackClick = () => {
		navigate(-1);
	};

	return (
		<>
			{localLoading ? (
				<TaskSkeleton />
			) : (
				<>
					<Grid
						sx={{ zIndex: 1000, position: "relative" }}
						className='g-padding-x-20--xs g-padding-y-10--xs g-hor-divider__solid--gray g-bg-color--white'
						style={{ borderLeft: "10px solid var(--success)", position: "sticky", top: 0 }}>
						<Grid container className='g-margin-t-0--xs'>
							<Grid item xs={8}>
								<div className='g-content-center-y--xs'>
									<i onClick={handleBackClick} className='material-symbols-sharp' style={{ cursor: "pointer", marginRight: "0.5rem" }}>
										arrow_back
									</i>
									<i className='material-symbols-sharp g-margin-r-5--xs g-font-size-20--xs'>emoji_objects</i>
									<InputV3 disabled={true} value={`${task?.idea?.name}`} name='taskName' />
								</div>
							</Grid>
							<Grid item xs={1}></Grid>
							<Grid item xs={3}>
								<div className='g-display-flex--xs' style={{ justifyContent: "flex-end" }}>
									<div className='g-display-flex--xs g-width-100-percent--xs g-margin-r-10--xs' style={{ alignItems: "center", justifyContent: "flex-end" }}>
										<div className='g-width-200--xs g-margin-l-5--xs'>
											<AutoCompleteSm
												onChange={(e: any, v: any) => handleTaskStateChange(v)}
												options={states}
												value={taskState}
												defaultValue={states[0]}
												placeholder='Select State'
											/>
										</div>
									</div>
									<div className='g-margin-r-5--xs'>
										<ButtonMenu menuOptions={moreMenuOptions} style={{ height: "33px" }} icon='more_horiz' color={"light"} radius={4} variant='contained' />
									</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<RightSidebarLayout drawer={task && <TaskDrawer task={task} />} drawerWidth={250} isDrawerOpen={true}>
						<div>{!localLoading && taskId && <FileManager files={filesInTask} taskId={taskId} />}</div>
					</RightSidebarLayout>
				</>
			)}
		</>
	);
};

export default TaskDetails;
function handleDeleteIdea(): void {}
