import { Grid, TextareaAutosize } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Idea } from "../../../models/Interface";
import { IdeaWebService } from "../../../services/IdeaWebService";
import { currentDate, localeDate } from "../../../utils/DateHelpers";
import { name_validation } from "../../../utils/InputValidations";
import Button from "../../atoms/Button";
import InputError from "../../atoms/InputError";
import InputV3 from "../../atoms/InputV3";
import MultipleSelectChip, { MultipleSelectChipOption } from "../../atoms/MultipleSelectChip";
import AutoCompleteFreeSolo from "../../atoms/MultipleSelectFreeSolo";
import UserChip from "../../atoms/UserChip";
import { useAuth } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";
import MoreOptionMenu, { MoreOptionMenuItem } from "../../molecules/MoreOptionMenu";
import { useNotification } from "../../NotificationContext";

const IdeaDetails = () => {
	const states = [{ label: "Draft" }, { label: "Up for Review" }, { label: "Approved" }, { label: "Required Changes" }, { label: "Rejected" }];
	let { ideaId } = useParams();
	const { currentWorkspace, user } = useAuth();
	const navigate = useNavigate();
	const { showLoading, hideLoading } = useLoading();
	const { showErrorMessage } = useNotification();
	const [lastChangeDatetime, setLastChangeDatetime] = useState<string>();

	const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
	const [ideaName, setIdeaName] = useState<string | undefined>("");
	const [ideaDescription, setIdeaDescription] = useState<string | undefined>("");
	const [ideaState, setIdeaState] = useState<any>(states[0]);
	const [reviewers, setReviewers] = useState<Array<string> | undefined>([]);
	const [references, setReferences] = useState<Array<string> | undefined>([]);
	const [tags, setTags] = useState<Array<string> | undefined>([]);
	const [workflows, setWorkflows] = useState<Array<string> | undefined>([]);
	const [idea, setIdea] = useState<Idea>({});
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const NEW_IDEA_ID = "new";

	const moreMenuOptions: MoreOptionMenuItem[] = [
		{
			title: "Delete Idea",
			icon: "delete",
			isDisable: ideaId == NEW_IDEA_ID,
			action: () => handleDeleteIdea(),
		},
	];

	let workflowOptions: MultipleSelectChipOption[] = [];
	let memberOptions: MultipleSelectChipOption[] = [];

	if (currentWorkspace) {
		memberOptions = currentWorkspace?.members.map((member) => {
			return {
				label: `${member.firstName} ${member.lastName ?? ""}`,
				value: member.email,
				icon: member.avatar ?? "K",
			};
		});
	}

	if (currentWorkspace) {
		workflowOptions = currentWorkspace.workflows.map((workflow) => {
			return { label: workflow.name, value: workflow.id };
		});
	}

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLoadingIdea = async () => {
		if (ideaId != null && ideaId != NEW_IDEA_ID) {
			showLoading();
			await IdeaWebService.fetchIdeaById(ideaId)
				.then((res) => {
					const ideaResponse = res as Idea;
					if (Object.keys(ideaResponse).length > 0) {
						setIdea(ideaResponse);
						setIdeaName(ideaResponse?.name);
						setIdeaState({ label: ideaResponse.status });
						setIdeaDescription(ideaResponse.description);
						setReviewers(ideaResponse.reviewers ?? []);
						setReferences(ideaResponse.references ?? []);
						setTags(ideaResponse.tags ?? []);

						let workflowIds: string[] = [];
						if (ideaResponse.ideaWorkflows && ideaResponse.ideaWorkflows?.length > 0) {
							workflowIds = ideaResponse.ideaWorkflows?.map((el) => {
								if (el && el.workflowId && typeof el.workflowId == "string") {
									return el.workflowId;
								} else {
									return "";
								}
							});
						}
						setWorkflows(workflowIds);
					}
				})
				.catch((err) => showErrorMessage(err.message))
				.finally(() => {
					hideLoading();
				});
		}
	};

	const handleSaveIdea = async () => {
		if (idea && currentWorkspace) {
			if (!idea.name || idea.name.length == 0) {
				showErrorMessage("Project Name can't be empty.\n Unable to save the Project");
				return;
			}
			setIsSavingInProgress(true);
			const workspaceId = currentWorkspace.id;
			idea.description = ideaDescription;
			// idea.status = ideaState.label;
			idea.status = "Approved";
			idea.references = references;
			idea.reviewers = reviewers;
			idea.tags = tags;
			if (idea?.createdBy) {
				idea.updatedBy = user.email;
				idea.updatedOn = currentDate();
			} else {
				idea.createdBy = user.email;
				idea.createdOn = currentDate();
			}
			if (workflows) {
				idea.ideaWorkflows = workflows.map((el) => {
					return { workspaceId: workspaceId, workflowId: el };
				});
			}

			await IdeaWebService.saveAndUpdateIdea(workspaceId, idea)
				.then((res: any) => {
					setIsSavingInProgress(false);
					setLastChangeDatetime(localeDate());
					navigate(`/${currentWorkspace.id}/ideas`);
				})
				.catch((err) => {
					showErrorMessage(err.message);
					setIsSavingInProgress(false);
				});
		}
	};

	const handleDeleteIdea = () => {
		if (ideaId) {
			showLoading();
			IdeaWebService.deleteIdea(ideaId)
				.then((data) => {
					if (currentWorkspace) {
						navigate(`/${currentWorkspace?.id}/ideas`);
					}
				})
				.catch((err) => showErrorMessage(err.message))
				.finally(() => {
					hideLoading();
				});
		}
	};

	useEffect(() => {
		handleLoadingIdea();
	}, [ideaId]);

	const handleReviewers = (value: any) => {
		setReviewers(value);
	};
	const handleReferences = (value: any) => {
		setReferences(value);
	};
	const handleTags = (value: any) => {
		setTags(value);
	};

	const handleAttachedWorkflows = (value: any) => {
		setWorkflows(value);
	};
	const handleIdeaNameChange = useCallback(
		(e: any) => {
			console.log(`In handleIdeaNameChange`);
			const value = e.target?.value;
			if (value != null) {
				setIdeaName(value);
				idea.name = value;
				setIdea(idea);
			}
		},
		[idea]
	);

	const handleIdeaDesciptionChange = useCallback(
		(e: any) => {
			console.log(`In handleIdeaDesciptionChange`);
			const value = e.target?.value;
			if (value != null) {
				setIdeaDescription(value);
				idea.description = value;
				setIdea(idea);
			}
		},
		[idea]
	);

	const handleIdeaStateChange = useCallback(
		(value: any) => {
			console.log(`In handleIdeaStateChange`);
			if (value != null) {
				setIdeaState(value);
				idea.status = value.label;
				setIdea(idea);
			}
		},
		[idea]
	);

	// Function to handle back navigation
	const handleBackClick = () => {
		navigate(-1);
	};

	return (
		<>
			<div>
				<Grid container className='g-padding-x-20--xs g-padding-y-10--xs g-hor-divider__solid--gray' style={{ borderLeft: "10px solid var(--warning)" }}>
					<Grid container className='g-margin-t-0--xs'>
						<Grid item xs={8}>
							<div className='g-content-center-y--xs'>
								<i onClick={handleBackClick} className='material-symbols-sharp' style={{ cursor: "pointer", marginRight: "0.5rem" }}>
									arrow_back
								</i>
								<i className='material-symbols-sharp g-margin-r-5--xs g-font-size-20--xs'>emoji_objects</i>
								<InputV3
									value={ideaName}
									onChange={(e: any) => handleIdeaNameChange(e)}
									validation={name_validation}
									name='ideaName'
									placeholder='Enter Project Name'
								/>
							</div>
						</Grid>
						<Grid item xs={1}></Grid>
						<Grid item xs={3}>
							<div className='g-display-flex--xs' style={{ justifyContent: "flex-end" }}>
								{/* <div className='g-display-flex--xs g-width-100-percent--xs g-margin-r-10--xs' style={{ alignItems: "center", justifyContent: "flex-end" }}>
									<div className='g-width-200--xs g-margin-l-5--xs'>
										<AutoCompleteSm
											onChange={(e: any, v: any) => handleIdeaStateChange(v)}
											options={states}
											value={ideaState}
											defaultValue={states[0]}
											placeholder='Select State'
										/>
									</div>
								</div> */}
								<div className='g-margin-r-10--xs'>
									<Button onClick={handleSaveIdea} text='Save & Close' icon='save' color={"primary"} radius={4} variant='contained'></Button>
								</div>
								<div className='g-margin-r-5--xs'>
									<Button style={{ height: "33px" }} onClick={handleMenuClick} icon='more_horiz' color={"light"} radius={4} variant='contained'></Button>
								</div>
							</div>
							<MoreOptionMenu anchorElement={anchorEl} closeHandler={handleMenuClose} items={moreMenuOptions} />
						</Grid>
					</Grid>

					<Grid container xs={12} style={{ alignItems: "center" }} className='g-margin-t-5--xs'>
						<Grid item xs={8}>
							<div className='g-display-flex--xs' style={{ justifyContent: "flex-start" }}>
								<p className='g-margin-b-0--xs g-margin-r-10--xs'>Created By</p>
								<UserChip id={idea.createdBy ?? ideaId == NEW_IDEA_ID ? user.email : ""} index={0} />
							</div>
						</Grid>
						<Grid item xs={4}>
							<div className='g-display-flex--xs g-width-100-percent--xs' style={{ alignItems: "center", justifyContent: "flex-end" }}>
								{ideaName?.length == 0 ? (
									<InputError message="Idea name can't be empty"></InputError>
								) : isSavingInProgress ? (
									<p className='g-margin-b-0--xs g-margin-r-10--xs g-text-align-right--xs g-font-weight--800'>Saving Project...</p>
								) : (
									lastChangeDatetime && (
										<p className='g-margin-b-0--xs g-margin-r-10--xs g-text-align-right--xs'>
											Last updated at: <b>{lastChangeDatetime}</b>
										</p>
									)
								)}
							</div>
						</Grid>
					</Grid>
				</Grid>

				<Grid container className='g-padding-y-40--xs g-padding-x-40--xs'>
					<Grid item xs={8}>
						<div className='g-margin-r-20--xs g-padding-x-25--xs g-padding-y-25--xs g-box-shadow__dark-lightest-v4 '>
							<div>
								<h6 className='g-margin-b-0--xs g-padding-y-0--xs'>Description</h6>
								<TextareaAutosize
									value={ideaDescription}
									onChange={(e: any) => handleIdeaDesciptionChange(e)}
									minRows={6}
									className='s-form-v1__input g-width-100-percent--xs g-margin-b-20--xs g-margin-t-20--xs g-padding-y-10--xs g-hor-border-1__solid--dark'
								/>
							</div>

							<div>
								<h6 className='g-margin-b-0--xs g-padding-y-0--xs'>References</h6>
								<AutoCompleteFreeSolo
									value={references}
									options={[]}
									onSelect={handleReferences}
									name='references'
									label=''
									placeholder='Add References'
									allowMultiple={true}
								/>
							</div>
							<div>
								<h6 className='g-margin-b-0--xs g-padding-y-0--xs'>Tags</h6>
								<AutoCompleteFreeSolo value={tags} options={[]} onSelect={handleTags} name='tags' label='' placeholder='Add Tags' allowMultiple={true} />
							</div>
							<div>
								<h6 className='g-margin-b-0--xs g-padding-y-0--xs'>Attached Workflows</h6>
								<MultipleSelectChip
									value={workflows}
									options={workflowOptions}
									onSelect={handleAttachedWorkflows}
									name='reviewers'
									label=''
									placeholder='Select Workflows'
									allowMultiple={false}
								/>
							</div>
							{/* <div>
								<h6 className='g-margin-b-0--xs g-padding-y-0--xs'>Reviewers</h6>
								<MultipleSelectChip
									value={reviewers}
									options={memberOptions}
									onSelect={handleReviewers}
									name='reviewers'
									label=''
									placeholder='Select Users'
									allowMultiple={true}
								/>
							</div> */}
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default IdeaDetails;
