import { Grid, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Idea, Task } from "../../models/Interface";
import { IdeaWebService } from "../../services/IdeaWebService";
import { WorkspaceWebService } from "../../services/WorkspaceWebService";
import { MultipleSelectChipBox } from "../atoms/MultipleSelectChipBox";
import MultipleSelectFreeSolo from "../atoms/MultipleSelectFreeSolo";
import UserChip from "../atoms/UserChip";
import { useAuth } from "../AuthContext";

const TaskDrawer = (props: { task: Task }) => {
	const navigate = useNavigate();
	const { currentWorkspace, user, isAdmin } = useAuth();

	const task = props.task;
	const taskId = task?.id;
	const ideaId = task?.idea?.id;

	const [ideaDetailsTags, setIdeaDetailsTags] = useState<any>([]);
	const [ideaDetailsDesc, setIdeaDetailsDesc] = useState();
	const [ideaDetails, setIdeaDetails] = useState<Idea>({});
	const [reviewers, setReviewers] = useState<string[]>(task.data?.reviewers || []);

	/** Idea Handler */
	const handleTags = useCallback(
		(value: any) => {
			setIdeaDetailsTags(value);
		},
		[ideaDetailsTags]
	);

	useEffect(() => {
		IdeaWebService.fetchIdeaById(ideaId).then((res: any) => {
			setIdeaDetails(res);
			setIdeaDetailsDesc(res.description);
			setIdeaDetailsTags(res.tags);
		});
	}, [ideaId]);

	const handleSaveIdea = async () => {
		const idea1 = { ...ideaDetails };

		const workspaceId = ideaDetails?.workspaceId;
		idea1.description = ideaDetailsDesc;
		idea1.tags = ideaDetailsTags;

		if (workspaceId && idea1 && ideaDetailsTags && ideaDetailsDesc) {
			await IdeaWebService.saveAndUpdateIdea(workspaceId, idea1)
				.then((res: any) => {
					//    setLastChangeDatetime(localeDate());
				})
				.catch((err) => {
					//    showErrorMessage(err.message);
				});
		}
	};

	useEffect(() => {
		if (isAdmin()) {
			let timer = setTimeout(handleSaveIdea, 1000);
			return () => clearTimeout(timer);
		}
	}, [ideaDetailsTags, ideaDetailsDesc]);

	/** Task Reviewers Handler */
	let members: any[] = [];
	if (currentWorkspace) {
		members = currentWorkspace?.members
			.filter((member) => !reviewers.includes(member.email))
			.map((member) => {
				return { label: `${member.firstName} ${member.lastName ?? ""}`, value: member.email, icon: member.avatar ?? "k" };
			});
	}

	let updatedReviewers: any[] = [];
	updatedReviewers = reviewers.map((reviewerEmail) => {
		if (!currentWorkspace) return [];

		const member = currentWorkspace?.members.find((member) => member.email === reviewerEmail);

		return member && { label: `${member.firstName} ${member.lastName ?? ""}`, value: member.email, icon: member.avatar ?? "k" };
	});

	const updateTaskReviewer = async (taskId: string, reviewersEmails: string[]) => {
		await WorkspaceWebService?.updateTaskReviewers(taskId, reviewersEmails)
			.then((res: any) => {
				console.log("Reviewers updated successfully:", res);
			})
			.catch((err: any) => {
				console.error("Failed to update reviewers:", err);
			});
	};

	// const handleReviewersChange = (value: any) => {
	// 	setReviewers(value);
	// 	console.log(value);
	// 	updateTaskReviewer(taskId, value);
	// };
	const handleReviewersDelete = (value: string) => {
		const removedReviewer = updatedReviewers.find((reviewer) => reviewer.value === value);
		updatedReviewers = updatedReviewers.filter((reviewer) => reviewer.value !== value);
		let updatedReviewerArray = updatedReviewers.map((reviewer) => reviewer.value);
		setReviewers(updatedReviewerArray);
		members.push(removedReviewer);
		updateTaskReviewer(taskId, updatedReviewerArray);
	};
	const handleReviewersAdd = (value: string) => {
		const removedMember = members.find((member) => member.value === value);
		members = members.filter((member) => member.value !== value);
		updatedReviewers.push(removedMember);
		let updatedReviewerArray = updatedReviewers.map((reviewer) => reviewer.value);
		setReviewers(updatedReviewerArray);
		updateTaskReviewer(taskId, updatedReviewerArray);
	};

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<Grid className='g-padding-y-5--xs g-padding-x-15--xs g-bg-color--gray-light g-margin-t-0--xs'>
					<div style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<label>Assigned To</label>
							<div style={{ fontWeight: "400" }}>
								<UserChip id={task.data?.assignedTo ?? user.email} index={0} />
							</div>
						</div>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<label>Work Type</label>
							<div className='g-display-flex--xs'>{task.type}</div>
						</div>
						<div style={{ display: "flex", flexDirection: "column" }}>
							{/* <h4 className='g-font-size-18--xs g-font-weight--600'>Reviewers</h4> */}
							{/* <div style={{ marginTop: "0" }}>
								<MultipleSelectChip
									value={reviewers}
									onSelect={handleReviewersChange}
									name='reviewers'
									options={members}
									label={"Reviewers"}
									placeholder='Select Users'
									allowMultiple={true}
									sx={{
										"& .MuiOutlinedInput-notchedOutline": {
											borderWidth: ".0rem !important",
										},
										"& .MuiSelect-select": {
											padding: "0 0rem",
										},
									}}
								/>
							</div> */}
							<MultipleSelectChipBox
								name='reviewers'
								label={"Reviewers"}
								options={members}
								value={updatedReviewers}
								placeholder={"Select Users"}
								handleDelete={handleReviewersDelete}
								handleAdd={handleReviewersAdd}
								allowMultiple={true}
							/>
						</div>

						<div style={{ display: "flex", flexDirection: "column" }}>
							<label>WorkFlow</label>
							<div className='g-display-flex--xs'>{task.workflow?.name}</div>
						</div>

						<div style={{ display: "flex", flexDirection: "column" }}>
							<label>Idea</label>
							<div style={{ fontWeight: "400" }}>
								<div
									style={{ textDecoration: "underline", fontSize: "13px", fontWeight: "400", cursor: "pointer" }}
									onClick={() => navigate(`/${currentWorkspace?.id}/ideas/${ideaId}`)}>
									{task.idea?.name}
								</div>
							</div>
						</div>

						<div style={{ display: "flex", flexDirection: "column" }}>
							<label>Description</label>
							<TextField
								placeholder='Enter Description'
								disabled={!isAdmin()}
								defaultValue={ideaDetailsDesc}
								value={ideaDetailsDesc}
								onChange={(e: any) => setIdeaDetailsDesc(e.target.value)}
								// rows={4}
								maxRows={8}
								fullWidth
								multiline
								size='small'
								margin='dense'
								// Keeping all the css as of now to ask doubts about it.
								// I'll remove it after some time.

								InputProps={{
									sx: {
										".css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input": {
											"font-size": "12px",
											"border-color": "gray",
											marginTop: "-2px",
											marginLeft: "-3px",
										},
										"& .MuiInputLabel-outlined": {
											borderColor: "#2e2e2e",
											fontWeight: "bold",
											"&.Mui-focused": {
												borderColor: "red",
												fontWeight: "bold",
											},
										},
										".MuiOutlinedInput-input": {
											borderColor: "red",
										},

										".css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
											borderColor: "red",
										},

										"&.MuiTextField-root": {
											"border-color": "red",
										},
										".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
											border: "1px solid gray",
										},
										"&:hover": {
											".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
												border: "1px solid gray",
											},
										},
										"&.Mui-focused fieldset": {
											borderColor: "red",
										},
										".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline:": {
											borderColor: "red",
										},
										"& .MuiOutlinedInput-notchedOutline": {
											borderColor: "gray",
										},
										"&.Mui-focused": {
											"& .MuiOutlinedInput-notchedOutline": {
												borderColor: "#7B7B7B",
												borderWidth: "1px",
												// Finally mil hi gaya
											},
										},
										marginTop: "-4px",
									},
								}}
							/>
						</div>

						<div style={{ display: "flex", flexDirection: "column" }}>
							<MultipleSelectFreeSolo
								disabled={!isAdmin()}
								value={ideaDetailsTags}
								options={[]}
								onSelect={handleTags}
								name='tags'
								label='Tags'
								placeholder='Add Tags'
								allowMultiple={true}
								disableClearable={true}
								sx={{
									input: {
										fontSize: "0.6125rem !important",
									},
								}}
							/>
						</div>
					</div>
				</Grid>
			</div>
		</>
	);
};

export default TaskDrawer;
