export enum TaskStatus {
	DRAFT,
	TODO,
	INPROGRESS,
	NEEDS_REVIEW,
	COMPLETE,
}
export const GetTaskStatusList = () => {
	const taskStatus: any = { DRAFT: "Draft", TODO: "To Do", INPROGRESS: "In Progress", NEEDS_REVIEW: "Needs Review", COMPLETE: "Complete" };
	var statesArray: any[] = [];
	Object.values(taskStatus).map((s, index) =>
		statesArray.push({
			label: s,
			value: TaskStatus[index],
		})
	);
	return statesArray;
};
export namespace StatusLabels {
	const status: any = {
		DRAFT: "Draft",
		TODO: "To Do",
		INPROGRESS: "In Progress",
		NEEDS_REVIEW: "Needs Review",
		COMPLETE: "Complete",
		APPROVED: "Approved",
		REFERENCED: "Referenced",
	};
	const statusColors: any = {
		DRAFT: "#aaaaaa",
		TODO: "#ed6c02",
		INPROGRESS: "#0288d1",
		NEEDS_REVIEW: "#fb6f92",
		COMPLETE: "#2a8f4f",
		APPROVED: "#2a8f4f",
		REFERENCED: "#0899FF",
	};

	export const getLabel = (value: string) => {
		if (value && (value as string) in status) return status[value];
	};

	export const getColor = (value: string) => {
		if ((value as string) in status) return statusColors[value];
	};
}
